export const APP_NAME = 'BITSAFIRA';
export const COLOR_THEME = '#32519F';
export const COLOR_LIGHT = '#f9f9f9';
export const LINK_PLATAFORMA = 'https://app.bitsafira.com.br/';
export const LINK_WHATSAPP = 'https://wa.me/5534997186432';
export const LINK_INSTAGRAM = 'https://www.instagram.com/bitsafira/';
export const LINK_YOUTUBE = 'https://www.youtube.com/playlist?list=PLmEtmIddEs7tqKCsfpJ8kjjv6HAgRkY9i';
export const LINKEDIN_DESENVOLVEDOR = 'https://www.linkedin.com/in/elison-carvalho-5447a9254/';

export const optionsMenu = [
    { label: 'Início', to: 'inicio' },
    { label: 'Apresentação', to: 'apresentacao' },
    { label: 'Recursos', to: 'recursos' },
    { label: 'Plano', to: 'plano' },
    { label: 'Integração', to: 'integracao' },
    // { label: 'Mais Serviços', to: 'servicos'}
]