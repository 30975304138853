import styled from "styled-components";
import { COLOR_LIGHT, COLOR_THEME } from "../../settings/config";

export const FooterContainer = styled.footer`
    background-color: ${COLOR_LIGHT};
    border-top: 1px solid ${COLOR_THEME};
`

export const FooterWrapper = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 20px auto 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`

export const SocialLogo = styled.div`
    color: ${COLOR_THEME};
    justify-content: start;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
    cursor: pointer;
`

export const WebSiteRights = styled.small`
    color: ${COLOR_THEME};
    margin-bottom: 16px;
    text-align: center;
`

export const WebSiteDeveloper = styled.a`
    color: ${COLOR_THEME};
    text-decoration: none;
`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
`

export const SocialIconLink = styled.a`
    color: ${COLOR_THEME};
    font-size: 24px;

    &:hover {
        transform: scale(1.2);
        transition: all 0.2s ease-in-out;
    }
`

