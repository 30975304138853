import {
    ApresentacaoContainer,
    ApresentacaoRow,
    ApresentacaoWrapper,
    Column1,
    Column2,
    Heading,
    Img,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine
} from "./SectionBaseElements";

const SectionBase = (props: any) => {
    return (
        <>
            <ApresentacaoContainer lightbg={props.lightBg ? 1 : 0} id={props.id}>
                <ApresentacaoWrapper>
                    <ApresentacaoRow imgstart={props.imgStart ? 1 : 0}>
                        <Column1>
                            <TextWrapper>
                                <Heading lighttext={props.lightText ? 1 : 0}>{props.headline}</Heading>
                                <TopLine lighttext={!props.lightBg ? 1 : 0}>{props.topLine}</TopLine>
                                <Subtitle lighttext={props.lightText ? 1 : 0}>{props.description}</Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={props.img} alt={props.alt} />
                            </ImgWrap>
                        </Column2>
                    </ApresentacaoRow>
                </ApresentacaoWrapper>
            </ApresentacaoContainer>
        </>
    )
}

export default SectionBase;