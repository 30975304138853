import styled from "styled-components";
import { COLOR_LIGHT, COLOR_THEME } from "../../settings/config";

export const ApresentacaoContainer = styled.div<any>`
    color: #fff;
    background: ${({ lightbg }) => (lightbg ? COLOR_LIGHT : COLOR_THEME)};
    padding: 70px 0;
    user-select: none;

    @media screen and (max-width: 864px) {
        padding: 50px 0;
    }
`

export const ApresentacaoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 'auto';
    margin-bottom: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const ApresentacaoRow = styled.div<any>`
    display: grid;
    grid-auto-columns: minmax()(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgstart }) => (imgstart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 864px) {
        grid-template-areas: ${({ imgstart }) => (imgstart ? `'col1' 'col2'` : `'col2 col2' 'col1 col1'`)};
    }
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;

    @media screen and (max-width: 864px) {
        text-align: center;
    }
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;

    @media screen and (max-width: 864px) {
        text-align: center;
    }
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
`

export const TopLine = styled.p<any>`
    color: ${({ lighttext }) => (lighttext ? '#fff' : COLOR_THEME)};;
    font-size: 18px;
    line-height: 18px;
    font-weight: 650;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 30px;

    @media screen and (max-width: 864px) {
        text-align: center;
        justify-content: center;
    }
`

export const Heading = styled.h1 <any>`
    margin-bottom: 24px; 
    font-size: 36px;
    line-height: 1.1;
    color: ${({ lighttext }) => (lighttext ? '#f7f8fa' : COLOR_THEME)};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const Title = styled.p`
    font-size: 50px;
    line-height: 65px;
    font-weight: bold;

    @media screen and (max-width: 864px) {
        font-size: 30px;
        line-height: 45px;
    }
`

export const Subtitle = styled.p <any>`
    max-width: 500px;
    font-size: 18px;
    line-height: 22px;
    color: ${({ lighttext }) => (lighttext ? '#f7f8fa' : '#010606')};
`

export const ImgWrap = styled.div`
    max-width: 550px;
    height: 100%;
`

export const Img = styled.img`
    width: 90%;
    margin: 20px;
    padding-right: 0;

    @media screen and (max-width: 864px) {
        max-width: 0;
        margin: 0;
    }
`

export const Card = styled.div`
    height: auto;
    background: ${COLOR_THEME};
    display: flex;
    flex-direction: flex-start;
    align-items: center;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const CardIcon = styled.div`
    min-height: 50px;
    min-width: 50px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
`

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
    margin-left: 10px; 
`

export const CardH1 = styled.p<any>`
    color: ${({ lighttext }) => (lighttext ? '#f7f8fa' : '#010606')};
    font-size: 18px;
    padding: 5px;
    font-weight: bold;
`
export const CardH2 = styled.p<any>`
    color: ${({ lighttext }) => (lighttext ? '#f7f8fa' : '#010606')};
    font-size: 16px;
    margin-top: 0; 
    font-weight: bold;
    text-align: left;
`

export const CardP = styled.p<any>`
    color: ${({ lighttext }) => (lighttext ? '#f7f8fa' : '#010606')};
    font-size: 1rem;
    max-width: 500px;
    text-align: left;

    @media screen and (max-width: 480px) {
        font-size: 0.9rem;
        max-width: 250px;
    }
`

