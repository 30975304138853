import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
    MobileIcon,
    Nav,
    NavItem,
    NavLinks,
    NavLogo,
    NavMenu,
    NavbarContainer,
    NavBtn,
    NavBtnLink,
    NavLogoImg
} from './NavbarElements';
import Logo from '../../images/Logo192.png'
import { APP_NAME, LINK_PLATAFORMA, optionsMenu } from '../../settings/config';

const Navbar = (props: any) => {
    const toggleTop = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleTop}>
                        <NavLogoImg src={Logo} alt='' /> {APP_NAME}
                    </NavLogo>
                    <MobileIcon onClick={props.toggle}> <FaBars /> </MobileIcon>
                    <NavMenu>
                        {
                            optionsMenu.map((option: any, i: number) => {
                                return (
                                    <NavItem key={i}>
                                        <NavLinks to={option.to} smooth={true} duration={300} offset={-70}> {option.label} </NavLinks>
                                    </NavItem>
                                )
                            })
                        }
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink href={LINK_PLATAFORMA} target='_blank' > {'Acesse a Plataforma'} </NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav >
        </>
    );
}

export default Navbar;