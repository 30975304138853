import ApresentacaoSvg from '../../images/apresentacao.svg'
import RecursosSvg from '../../images/recursos.svg'
import PlanosSvg from '../../images/planos.svg'
import IntegracaoSvg from '../../images/integracao.svg'
import DadosSvg from '../../images/dados.png'
//import ServicosSvg from '../../images/servicos.svg'
import { CardApresentacao, CardPlano, getRecursos } from './DataUtils';
import { FaWhatsapp } from 'react-icons/fa';
import { BsMegaphone } from 'react-icons/bs';
//import { CgWebsite } from 'react-icons/cg';
//import { FiUploadCloud } from 'react-icons/fi';
import { HiOutlineDevicePhoneMobile } from 'react-icons/hi2';
import { Title } from './SectionBaseElements'

export const apresentacaoData = {
    id: 'apresentacao',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'A plataforma que você precisa',
    headline: '',
    description:
        <>
            <CardApresentacao lighttext={true} title={'Cobranças Automáticas'} description={'Notifique antes, no dia e após o vencimento. Você escolhe como, e o melhor... tudo automático.'} icon={<FaWhatsapp style={{ width: '30px', height: '30px' }} />} />
            <CardApresentacao lighttext={true} title={'Envio Martketing'} description={'Conquiste novos clientes de maneira automatizada, rápida e prática.'} icon={<BsMegaphone style={{ width: '30px', height: '30px' }} />} />
            <CardApresentacao lighttext={true} title={'Acessibilidade'} description={'Disponível para smartphones e desktops.'} icon={<HiOutlineDevicePhoneMobile style={{ width: '30px', height: '30px' }} />} />
        </>,
    imgStart: false,
    img: ApresentacaoSvg,
    alt: 'Dashboard',
    dark: true,
    darkText: false,
};

export const recursosData = {
    id: 'recursos',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Nossos recursos que vão lhe ajudar a gerenciar o seu negócio',
    headline: '',
    description: getRecursos(),
    imgStart: true,
    img: RecursosSvg,
    alt: 'Recursos',
    dark: false,
    darkText: true,
};

export const planosData = {
    id: 'plano',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: '',
    description:
        <CardPlano title={'Plano Ouro'}
            description={
                <>
                    <p><b>API WhatsApp Própria</b></p>
                    <p><b>Envio de cobranças automáticas</b></p>
                    <p><b>Navegação otimizada</b></p>
                    <p>Envio de Mensagens em Massa Texto</p>
                    <p>Clientes Ilimitados</p>
                    <p>Serviços Ilimitados</p>
                    <p>Central de Ajuda</p>
                    <p>Suporte via WhatsApp / Acesso Remoto</p>
                </>
            } />
    ,
    imgStart: false,
    img: PlanosSvg,
    alt: 'Plano',
    dark: true,
    darkText: false,
}

export const integracaoData = {
    id: 'integracao',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine:
        <>
            <p>Inove na Comunicação</p><br />
            <p>Notificações via whatsapp direto no seu software</p>
        </>,
    headline: '',
    description:
        <>
            <p>Permita que seus clientes enviem cobranças, promoções, mensagens... como você desejar.</p>
            <p>Sua equipe não precisa mais se preocupar com burocracias, atualizações e instabilidades no serviço.</p>
            <p>Nossa solução ficará responsável por realizar todo o processo de monitoramento, atualização e padronização no envio de mensagens pelo Whatsapp.'</p>
        </>
    ,
    imgStart: true,
    img: IntegracaoSvg,
    alt: 'Integração',
    dark: false,
    darkText: true,
};

/*export const servicosData = {
    id: 'servicos',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Nós também podemos te oferecer',
    headline: '',
    description:
        <>
            <CardApresentacao lighttext={true} title={'Soluções Web'} description={'Transforme suas ideias em realidade com nossos serviços de desenvolvimento sob medida. Juntos, vamos tornar sua visão digital uma conquista notável.'} icon={<CgWebsite style={{ width: '30px', height: '30px' }} />} />
            <CardApresentacao lighttext={true} title={'Hospedagem de Sites'} description={'Garantimos que sua presença na web esteja sempre acessível e em pleno funcionamento. Conte conosco para cuidar dos detalhes técnicos enquanto você foca no crescimento do seu negócio.'} icon={<FiUploadCloud style={{ width: '30px', height: '30px' }} />} />
        </>,
    imgStart: false,
    img: ServicosSvg,
    alt: 'Serviços',
    dark: true,
    darkText: false,
}*/

export const nossosDados = {
    id: '',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Nossos Clientes',
    headline: '',
    description:
        <>
            <Title>165+ Clientes</Title>
            <Title>10+ Revendedores</Title>
            <Title>20+ Estados</Title>
        </>
    ,
    imgStart: false,
    img: DadosSvg,
    alt: 'Serviços',
    dark: true,
    darkText: false,
}