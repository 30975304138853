import { LINK_PLATAFORMA, optionsMenu } from "../../settings/config";
import {
    SidebarContainer,
    CloseIcon,
    Icon,
    SidebarWrapper,
    SidebarLink,
    SideBtnWrap,
    SidebarBtn,
    SidebarMenu
} from "./SidebarElements";

const Sidebar = (props: any) => {
    return (
        <SidebarContainer open={props.open} onClick={props.toggle}>
            <Icon onClick={props.toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {
                        optionsMenu.map((option: any, i: number) => {
                            return (
                                <SidebarLink key={i} to={option.to} smooth={true} duration={300} offset={-70} onClick={props.toggle}> {option.label} </SidebarLink>
                            )
                        })
                    }
                </SidebarMenu>
            </SidebarWrapper>
            <SideBtnWrap>
                <SidebarBtn href={LINK_PLATAFORMA} target='_blank' > {'Acesse a Plataforma'}</SidebarBtn>
            </SideBtnWrap>
        </SidebarContainer>
    )
}

export default Sidebar;