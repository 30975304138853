import { FaInstagram, FaYoutube } from "react-icons/fa";
import {
    FooterContainer,
    FooterWrapper,
    SocialIconLink,
    SocialIcons,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebSiteDeveloper,
    WebSiteRights
} from "./FooterElements";
import { animateScroll as scroll } from 'react-scroll';
import { APP_NAME, LINKEDIN_DESENVOLVEDOR, LINK_INSTAGRAM, LINK_YOUTUBE } from "../../settings/config";

const Footer = () => {
    const toggleTop = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrapper>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo onClick={toggleTop}>{APP_NAME}</SocialLogo>
                        <WebSiteRights>
                            {APP_NAME} © {new Date().getFullYear()}{'. Todos os direitos reservados.'} <br />
                            {'Desenvolvido por: '} <WebSiteDeveloper href={LINKEDIN_DESENVOLVEDOR} target="_black"><b>{'Elison Carvalho'}</b></WebSiteDeveloper>
                        </WebSiteRights>
                        <SocialIcons>
                            <SocialIconLink href={LINK_INSTAGRAM} target="_black" arial-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href={LINK_YOUTUBE} target="_black" arial-label="Youtube">
                                <FaYoutube />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer;